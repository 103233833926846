// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:a0426bc7-3617-4835-86a1-4401bee23846",
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": [],
        "passwordPolicyMinLength": 8
    },
    "oauth": {
        "redirectSignOut": "http://localhost:3000/,https://develop.d1nrwq9kyzkrhw.amplifyapp.com/,https://develop.part.dev.imagesimilarity.tecalliance.net/,https://part.dev.imagesimilarity.tecalliance.net/",
        "domain": "jnp-auth-develop.auth.eu-central-1.amazoncognito.com",
        "responseType": "code",
        "redirectSignIn": "http://localhost:3000/,https://develop.d1nrwq9kyzkrhw.amplifyapp.com/,https://develop.part.dev.imagesimilarity.tecalliance.net/,https://part.dev.imagesimilarity.tecalliance.net/",
        "scope": [
            "openid",
            "profile",
            "email"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_user_pools_id": "eu-central-1_CZUbzquZi",
    "aws_user_pools_web_client_id": "2dtfpd76ao0rlnjmet53uj6tci",
    "aws_cognito_region": "eu-central-1",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_custom_provider": "JNPdevelopFronteoAzureAD4179AACC",
    "aws_cognito_social_providers": [],
    "aws_appsync_apiKey": "da2-l2zswr2byzh43b3fsq4h277ufq",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_graphqlEndpoint": "https://4kiay2dqkjdctlqvhcbpneihpi.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_user_files_s3_bucket": "jnp-develop-frontend-s3nesteds-jnpdevelop2436ec1b-1kus7vsbiwg8c",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "backend": {
        "region": "eu-central-1"
    },
    "amplify_appid": "d1nrwq9kyzkrhw",
    "aws_project_region": "eu-central-1"
};

export default awsmobile;
